define(['$window', 'simpleSlider', 'app', 'accessibilityFocusHelper'], function($window, simpleSlider, app, accessibilityFocusHelper) {

  const profileService = () => {
    
    const component = {};

    const _config = {
      sliderOptions: {
        wrapper: '.profileService_sliderWrapper',
        slide: '.profileService_slideItem',
        loop: false,
        switchKey: false,
        hasSwipe: false
      },
      properties: {
        isMobile: $window.outerWidth < 500,
        topFooterHeight: 0,
      },
      slideProperties: {
        slideCurrent: null,
        slideCurrentChild: null,
        slideCurrentNumber: 0,
        allSlides: null,
        totalSlideNumber: 0
      },
    };

    const _setupPageElements = (element) => {

      const elements = {
        getStarted: element.querySelector('.profileService_headerWrapper-getStarted'),
        headerWrapper: element.querySelector('.profileService_headerWrapper'),
        headerWrapperWidget: element.querySelector('.profileService_widget_headerWrapper'),
        profileDescriptors: element.querySelector('.profileService_profileDescriptors'),
        progressBar: element.querySelector('.profileService_progressBar_progress'),
        nextButtons: element.querySelectorAll('.profileService_buttonNext'),
        previousButtons: element.querySelectorAll('.profileService_buttonPrevious'),
        submitButtons: element.querySelectorAll('.profileService_buttonSubmit'),
        progressBars: element.querySelectorAll('.profileService_progressBar_progress'),
        currentQuestion: null,
        inputTypeDate: element.querySelectorAll('input[type=date].profileService_profileDescriptorsSection-input'),
        buttonWrapper: element.querySelector('.profileService_formQuestionsButtonWrapper')
      }

      component.config.elements = elements;
    };
    /**
     * Initialise
     */
    const _init = element => {

      component.element = element;
      component.setupPageElements(element);
      component.slider = new simpleSlider('.simpleSlider-beautyProfile', component.config.sliderOptions);
      component.config.properties.isProfilePage = component.isProfilePage();
      // starting methods 
      component.getTopFooterHeight();
      component.addListeners();
      component.dateInput();
      component.setupProgressBars();
    }

    const _isProfilePage = () => {
      if(!$window.location.pathname) return false;
      return $window.location.pathname.includes('profile');
    }

    //debounce function
    const _debounce = (func, delay = 1000) => {
      let timeoutId;
      return (...args) => {

        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
          func.apply(null, args);
        }, delay);
      };
    }

    const _addListeners = () => {
      component.config.elements.getStarted.addEventListener('click', component.getStartedClick);

      component.config.elements.nextButtons.forEach(next => {
        next.addEventListener('click', component.nextHandler);
      });

      component.config.elements.previousButtons.forEach(previous => {
        previous.addEventListener('click', component.previousHandler);
      });

      component.config.elements.submitButtons.forEach(submit => {
        submit.addEventListener('click', component.submitHandler);
      });

      $window.addEventListener('resize', component.windowIsResized);

      component.config.elements.inputTypeDate.forEach(input => {
        input.addEventListener('click', component.typeTextFormatClick);
      });

      component.slider.wrapper.addEventListener('click', component.errRemoveHandler);

    }

    const _getTopFooterHeight = () => {
      if(!component.config.properties.isProfilePage) return;

      if (document.querySelector('.footer')) {
        component.config.properties.topFooter = document.querySelector('.footer').offsetTop;
      } else if (document.querySelector('.eastendFooter')) {
        component.config.properties.topFooter = document.querySelector('.eastendFooter').offsetTop;
      }
    }

    const _windowIsResized = () => component.debounce(component.sliderHeight());

    /**
     * Clicking on get started button calls the showSlider function
     */
    const _getStartedClick = () => {
      component.showSlider(component.config.properties.isMobile);
      component.navigateToButton(component.config.elements.getStarted);
      component.config.elements.currentQuestion = component.slider.slides[component.slider.current].querySelector('.profileService_questionNumber');
      accessibilityFocusHelper.focus(component.config.elements.currentQuestion);
      component.backButton();
    }

    /**
     * This hides the first slide and shows the contents in the slider
     */
    const _showSlider = (isMobile) => {
      // use toggle here?
      app.element.addClass('profileService-hide', component.config.elements.headerWrapper);
      app.element.addClass('profileService-hide', component.config.elements.headerWrapperWidget);
      app.element.removeClass('profileService-hide', component.config.elements.profileDescriptors);

      component.sliderHeight();

      if (isMobile) {
        component.fixedNavButtons();
      }
    }

    const _updateSlideCurrentProperties = () => {
      component.config.slideProperties.slideCurrent = component.slider.currentSlide;
      component.config.slideProperties.slideCurrentChild = component.slider.currentSlide.children[0];
      component.config.slideProperties.slideCurrentNumber = component.slider.currentSlide.children[0].getAttribute('data-slidenumber');
      component.config.slideProperties.allSlides = component.slider.slides;
      component.config.slideProperties.totalSlideNumber = component.slider.slides.length;
    }
    /**
     * On the first slide, the hideBackButton function is called
     */
    const _backButton = () => {

      component.updateSlideCurrentProperties();

      if (component.config.slideProperties.slideCurrentNumber === '0') {
        component.toggleBackButton();
      }
    }

    /**
     * This hides the back button
     */
    const _toggleBackButton = () => {
      component.config.elements.previousButtons.forEach(previousButton => {
        previousButton.classList.toggle('button-hide');
      });
    }

    /**
     * Adds anchor to profile container
     */
    const _navigateToButton = (button) => {
      const buttonRect = button.getBoundingClientRect();
      const scrollX = window.scrollX + (buttonRect.left + buttonRect.width / 2) - (window.innerWidth / 2);
      const scrollY = window.scrollY + (buttonRect.top + buttonRect.height / 2) - (window.innerHeight / 2);

      window.scrollTo({ 
          top: scrollY, 
          left: scrollX, 
          behavior: 'smooth'
      });
  }

    /**
     * Adds click to next button and calls validation function
     */
    const _nextHandler = (e) => {
      e.preventDefault();

      let origSlide = component.slider.current;
      
      component.navigateToButton(component.config.elements.profileDescriptors);

      component.validation(component.slider.current + 1);
      accessibilityFocusHelper.focus(component.slider.slides[component.slider.current].querySelector('.profileService_questionNumber'));
      component.displaySubmit();

      while (!component.renderConditionsMet() && component.slider.current + 1 < component.config.slideProperties.totalSlideNumber) {
        component.moveSlide(component.slider.current + 1);
        accessibilityFocusHelper.focus(component.slider.slides[component.slider.current].querySelector('.profileService_questionNumber'));
        component.displaySubmit();
      }

      //If we reached the end and there are still render conditions not met, then submit the profile
      if (!component.renderConditionsMet()) {

        component.moveSlide(origSlide);
        component.element.querySelector('form.profileService_form').submit();

        component.nextButton = component.element.querySelector('.profileService_profileDescriptors-buttonNext');
        component.submitButton =component.element.querySelector('.profileService_profileDescriptors-submit');
        app.element.setAttribute('disabled', true, component.nextButton);
        app.element.setAttribute('disabled', true, component.submitButton);
      }
    }

    /**
     * Adds click to previous button and calls validation function
     */
    const _previousHandler = (e) => {
      e.preventDefault();

      component.navigateToButton(component.config.elements.profileDescriptors);

      component.moveSlide(component.slider.current - 1);
      accessibilityFocusHelper.focus(component.slider.slides[component.slider.current].querySelector('.profileService_questionNumber'));
      component.hideSubmit();
      component.backButton();

      while (!component.renderConditionsMet() && component.slider.current > 0) {
        component.moveSlide(component.slider.current - 1);
        accessibilityFocusHelper.focus(component.slider.slides[component.slider.current].querySelector('.profileService_questionNumber'));
        component.backButton();
      }
    }

    /**
     * When click on the Date of Birth input field, the text error message is removed
     */
    const _typeTextFormatClick = () => {
      component.removeErr();
    };

    /**
     * Submits form after validation
     */
    const _submitHandler = (e) => {
      e.preventDefault();

      if (component.validation(component.slider.current)) {
        component.element.querySelector('form.profileService_form').submit();
      }
    }

    /**
     * Checks that at least one input is checked in the current section
     * Checks that if input is type text, it calls typeTextFormat function
     * @param index
     * @return boolean
     */
    const _validation = (index) => {
      const inputs = component.slider.currentSlide.querySelectorAll('input');
      const inputType = inputs[0].getAttribute('type');

      if (inputType === 'radio') {
        return component.validityInput(inputs, index);
      }

      if (inputType === 'date') {
        return component.typeDateFormat(index, inputs.length > 0 ? inputs[0] : null);
      }

      if (inputType === 'number') {
        return component.typeNumberFormat(index, inputs.length > 0 ? inputs[0] : null);
      }

      // File upload
      if (inputType === 'file') {
        return component.typeFileUploadFormat(index, inputs.length > 0 ? inputs[0] : null);
      }

      if (inputType === 'text') {
        // Dropdown
        if (component.slider.currentSlide.querySelector('.simpleDropdownInput')) {
          return component.typeDropdownFormat(index, inputs.length > 0 ? inputs[0] : null);
        // Free text
        } else {
          return component.typeTextFormat(index, inputs.length > 0 ? inputs[0] : null);
        }
      }

      if (inputType === 'checkbox') {
        const maxQuestions = component.slider.currentSlide.querySelector('[data-maxQuestions]');
        if (maxQuestions) {
          const maxNumber = maxQuestions.getAttribute('data-maxQuestions');
          return component.checkMax(maxNumber, inputs, index);
        } else {
          return component.validityInput(inputs, index);
        }
      }
    }

    /**
     * Checks that the render conditions are met for the current question
     * 
     * This is s stupid name for what this does!!
     */
    const _renderConditionsMet = () => {

      // does the current slide have render conditions?
      const renderConditionsElement = component.slider.currentSlide.querySelector('[data-renderConditions]');
      // if so, check them
      if (renderConditionsElement) {
        
        const renderConditions = renderConditionsElement.getAttribute('data-renderConditions').split(';');

        const form = new FormData(component.element.querySelector('form.profileService_form'));

        for (let i = 0; i < renderConditions.length; i ++) {
          const condition = renderConditions[i].split(',');

          if (condition.length !== 3) {
            continue;
          }

          let matched = form.getAll('profiles:' + condition[0].trim()).includes(condition[2].trim());
          if ((condition[1].trim() === 'EQUAL_TO' && !matched) || (condition[1].trim() === 'NOT_EQUAL_TO' && matched)) {
            return false;
          }
        }
      }

      return true;
    }

    /**
     * validates input
     * @param inputs and slider index
     * @return boolean
     */

    const _validityInput = (inputs, index) => {
      const valid = component.inputCheck(inputs).length;

      if (valid) {
        component.moveSlide(index);
        return true;
      } else {
        component.showErr();
        return false;
      }
    }

    /**
     * Checks if inputs is checked
     * @param inputs
     * @return length of array
     */

    const _inputCheck = (inputs) => {
      return [...inputs].filter((element) => element.checked);
    }

    /**
     * validates max response
     * @param max response, inputs html nodelist, current slide
     * @return boolean
     */

    const _checkMax = (max, inputs, index) => {
      let count = component.inputCheck(inputs).length;

      if (count <= max || max === 0) {
        component.validityInput(inputs, index);
        return true;
      } else {
        component.showMaxError();
        return false;
      }
    }

    const _isValidDate = (dateString) => {
      // First check for the pattern
      const regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

      if (!regex_date.test(dateString)) {
        return false;
      }

      // Parse the date parts to integers
      const parts = dateString.split('-');
      const day = parseInt(parts[2], 10);
      const month = parseInt(parts[1], 10);
      const year = parseInt(parts[0], 10);

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month === 0 || month > 12) {
        return false;
      }

      var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29;
      }

      // Check the range of the day
      return day > 0 && day <= monthLength[month - 1];
    }

    /**
     * Checks date entered is in the correct format (YYYY-MM-DD)
     * @param index
     */
    const _typeDateFormat = (index, input) => {
      const dateInputValue = input.value;

      if (component.isValidDate(dateInputValue) && input.checkValidity()) {
        component.moveSlide(index);
        component.removeErr();
        return true;
      } else {
        component.showDateErr();
        return false;
      }
    }

    /**
     * Checks number entered is a valid number
     * @param index
     * @param input
     */
    const _typeNumberFormat = (index, input) => {
      if (input != null && !isNaN(input.value) && input.checkValidity()) {
        component.moveSlide(index);
        component.removeErr();
        return true;
      } else {
        component.showTextErr();
        return false;
      }
    }

    /**
     * Checks textfield is non-empty
     * @param index
     * @param input
     */
    const _typeTextFormat = (index, input) => {
      if (input != null && input.value.length > 0 && input.checkValidity()) {
        component.moveSlide(index);
        component.removeErr();
        return true;
      } else {
        component.showTextErr();
        return false;
      }
    }

    /**
     * Checks fileupload is non-empty
     * @param index
     * @param input
     */
    const _typeFileUploadFormat = (index, input) => {
      if (input != null) {
        component.moveSlide(index);
        component.removeErr();
        return true;
      } else {
        component.showTextErr();
        return false;
      }
    }

    /**
     * Checks dropdownInput
     * @param index
     * @param input
     */
    const _typeDropdownFormat = (index, input) => {
      const dropdown = component.slider.currentSlide.querySelector('.simpleDropdownInput');
      const choices = JSON.parse(dropdown.getAttribute('data-choices'));

      const anyMatch = choices.some(({display}) => display.toLowerCase() === input.value.toLowerCase().trim());

      if (anyMatch) {
        component.moveSlide(index);
        component.removeErr();
      } else {
        component.showErr();
      }

      return anyMatch;
    }

    /**
     * This moves slider to the next slide
     * @param index
     */
    const _moveSlide = (index) => {
      component.backButton();
      component.slider.moveSlide(index);
      component.sliderHeight();
    }

    /**
     * dynamic slider height
     */

    const _sliderHeight = () => {
      const currentSlideHeight = component.slider.currentSlide.clientHeight;
      component.slider.wrapper.style.height = `${currentSlideHeight + 60}px`;
      component.backToTop();
    }

    const _setupProgressBars = () => {
      // check progressbars exist
      if (component.config.elements.progressBars.length > 0) {
        component.config.elements.progressBars.forEach((progressBar, index) => {
          let percentage = 100 / component.config.elements.progressBars.length;
          let width = percentage * (index + 1);
          progressBar.style.width = `${width}%`;
        }); 
      }
    }

    const _backToTop = () => {
      if (!component.config.properties.isProfilePage) return;
      app.element.scrollTo(1, 400);
    }

    /**
     * fixed nav to relative on scroll
     */

    const _fixedNavButtons = () => {
      if (!component.config.properties.isProfilePage) return;
      const navButtons = component.element.querySelector('.profileService_formQuestionsButtonWrapper');
      $window.addEventListener('scroll', () => {
        const isRelative = ($window.scrollY + $window.innerHeight) <= component.topFooter;
        component.toggleNavButtons(navButtons, isRelative);
      }); 
    }

    const _toggleNavButtons = (navButtons, isRelative) => {
      if (isRelative) {
        app.element.removeClass('profileService_formQuestionsButtonWrapper-relative', navButtons);
      } else {
        app.element.addClass('profileService_formQuestionsButtonWrapper-relative', navButtons);
      }
    }

    /**
     * Adds click to current slide and calls removeErr function
     */
    const _errRemoveHandler = () =>  component.removeErr();

    /**
     * Shows the error message
     */
    const _showErr = () => {
      component.backToTop();
      const error = component.slider.currentSlide.querySelector('.profileService_profileDescriptorsSection-errorMessage');
      if (error) {
        app.element.removeClass('profileService-hide', error);
        // resize slider
        component.sliderHeight();
      }
    }

    /**
     * Shows text error message
     */
    const _showTextErr = () => {
      component.backToTop();
      const textError = component.slider.currentSlide.querySelector('.profileService_profileDescriptorsSection-textErrorMessage');
      if (textError) {
        app.element.removeClass('profileService-hide', textError);
      }
    }

    /**
     * Shows text error message
     */
    const _showDateErr = () => {
      component.backToTop();
      const dateError = component.slider.currentSlide.querySelector('.profileService_profileDescriptorsSection-dateErrorMessage');
      if (dateError) {
        app.element.removeClass('profileService-hide', dateError);
      }
    }

    /**
     * show max error message if responses exceed maximum
     */
    const _showMaxError = () => {
      component.backToTop();
      const maxError = component.slider.currentSlide.querySelector('.profileService_profileDescriptorsSection-maxErrorMessage');
      return maxError && app.element.removeClass('profileService-hide', maxError);
    }

    /**
     * removes error messages
     */
    const _removeErr = () => {
      const errorElements = component.slider.currentSlide.querySelectorAll('[data-message=error]');
      errorElements.forEach((errorElement) => {
        errorElement.classList.add('profileService-hide');
      });
    }

    /**
     * Adds placeholder to date fields to show date format
     */
    const _dateInput = () => {
      component.config.elements.inputTypeDate.forEach(inputTypeDate => {
        inputTypeDate.placeholder = 'YYYY-MM-DD';
      });
    }

    /**
     * When on last slide, the submit button replaces the next button
     */
    const _displaySubmit = () => {
      component.updateSlideCurrentProperties();

      const showSubmit = parseInt(component.config.slideProperties.slideCurrentNumber) === (component.config.slideProperties.totalSlideNumber - 1);

      if (showSubmit) {
        component.showSubmit();
      } else {
        component.hideSubmit();
      }
    }

    /**
     * This hides the next button and shows the submit button
     */

    const _getCurrentSlideButtons = () => {
      let currentSlideSubmitButtons = component.slider.currentSlide.querySelectorAll('.profileService_buttonSubmit');
      let submitButton = component.config.elements.buttonWrapper.querySelector('.profileService_buttonSubmit');
      let currentSubmitButtons = [...currentSlideSubmitButtons, submitButton];
      let currentNextButtons = component.slider.currentSlide.querySelectorAll('.profileService_buttonNext');
      let nextButton = component.config.elements.buttonWrapper.querySelector('.profileService_buttonNext');
      currentNextButtons = [...currentNextButtons, nextButton];
      return {currentSubmitButtons, currentNextButtons};
    };

    const _showSubmit = () => {
      component.getCurrentSlideButtons().currentSubmitButtons.forEach(currentSubmitButton => {
        currentSubmitButton.classList.remove('button-hide');
        currentSubmitButton.removeAttribute('disabled');
      });
      
      component.getCurrentSlideButtons().currentNextButtons.forEach(currentNextButton => {
        currentNextButton.classList.add('button-hide');
        currentNextButton.setAttribute('disabled', 'disabled');
      });

    };

    /**
     * This shows the next button and hides the submit button
     */
    const _hideSubmit = () => {
      component.getCurrentSlideButtons().currentSubmitButtons.forEach(currentSubmitButton => {
        currentSubmitButton.classList.add('button-hide');
        currentSubmitButton.setAttribute('disabled', 'disabled');
      });

      component.getCurrentSlideButtons().currentNextButtons.forEach(currentNextButton => {
        currentNextButton.classList.remove('button-hide');
        currentNextButton.removeAttribute('disabled');
      });
    };

    component.config = _config;
    component.init = _init;
    component.isProfilePage = _isProfilePage;
    component.setupPageElements = _setupPageElements;
    component.addListeners = _addListeners;
    component.getTopFooterHeight = _getTopFooterHeight;
    component.debounce = _debounce;
    component.windowIsResized = _windowIsResized;
    component.getStartedClick = _getStartedClick;
    component.showSlider = _showSlider;
    component.updateSlideCurrentProperties = _updateSlideCurrentProperties;
    component.backButton = _backButton;
    component.toggleBackButton = _toggleBackButton;
    component.nextHandler = _nextHandler;
    component.previousHandler = _previousHandler;
    component.typeTextFormatClick = _typeTextFormatClick;
    component.submitHandler = _submitHandler;
    component.navigateToButton = _navigateToButton;
    component.validation  = _validation;
    component.renderConditionsMet = _renderConditionsMet;
    component.validityInput = _validityInput;
    component.inputCheck  = _inputCheck;
    component.checkMax = _checkMax;
    component.isValidDate = _isValidDate;
    component.typeDateFormat = _typeDateFormat;
    component.typeNumberFormat = _typeNumberFormat;
    component.typeTextFormat = _typeTextFormat;
    component.typeFileUploadFormat  = _typeFileUploadFormat;
    component.typeDropdownFormat  = _typeDropdownFormat;
    component.moveSlide = _moveSlide;
    component.sliderHeight = _sliderHeight;
    component.setupProgressBars = _setupProgressBars;
    component.backToTop = _backToTop;
    component.fixedNavButtons = _fixedNavButtons;
    component.toggleNavButtons  = _toggleNavButtons;
    component.errRemoveHandler = _errRemoveHandler;
    component.showErr = _showErr;
    component.showTextErr = _showTextErr;
    component.showDateErr = _showDateErr;
    component.showMaxError = _showMaxError;
    component.removeErr = _removeErr;
    component.dateInput = _dateInput;
    component.displaySubmit = _displaySubmit;
    component.getCurrentSlideButtons = _getCurrentSlideButtons;
    component.showSubmit = _showSubmit;
    component.hideSubmit = _hideSubmit;
    return component;
  }

  return profileService;


});
